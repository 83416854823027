export const Flatten = (obj, prefix = '', res = {}, find = 'flatData') => {
  if (!obj) {
    return {}
  }
  return Object.entries(obj).reduce((r, [key, val]) => {
    const k = `${prefix}${key}`
    if (Array.isArray(val)) {
      val = val.map((f) => {
        if (typeof f !== 'string') {
          return Flatten(f)
        }
        return f
      })
    }
    if (typeof val === 'object' && key === 'flatData') {
      Flatten(val, ``, r)
    } else {
      res[k] = val
    }
    return r
  }, res)
}

export const PickFirst = (data = []) => {
  if (!data) {
    return {}
  }

  if (data.length > 0) {
    return data[0]
  }
}

export const CamelCase = (string) => {
  return string.charAt(0).toLowerCase() + string.slice(1)
}

export const Slugify = (string) =>
  string
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-')

export const CheckFlexGap = () => {
  // create flex container with row-gap set
  var flex = document.createElement('div')
  flex.style.display = 'flex'
  flex.style.flexDirection = 'column'
  flex.style.rowGap = '1px'

  // create two, elements inside it
  flex.appendChild(document.createElement('div'))
  flex.appendChild(document.createElement('div'))

  // append to the DOM (needed to obtain scrollHeight)
  document.body.appendChild(flex)
  var isSupported = flex.scrollHeight === 1 // flex container should be 1px high from the row-gap
  flex.parentNode.removeChild(flex)

  return isSupported
}

export const PhoneLink = (phoneNumber) => {
  if (phoneNumber) {
    let phoneString = phoneNumber.replace(/[^\w\s]/gi, '')
    phoneString = phoneString.replace(' ', '')
    let phoneLink = 'tel:+1' + phoneString
    return phoneLink
  }
}

export const FormatCurrency = (num) => {
  return '$' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export function StripTrailingSlash(str) {
  if (str.substr(-1) === '/') {
    return str.substr(0, str.length - 1)
  }
  return str
}

const isDocumentLink = (link) => {
  const fileExtension = link.match(/\.\w{3,4}($|\?)/);

  return !!(fileExtension && fileExtension[0]);
};

const isAnchorLink = (link) => {
  return !!link.split('#')[1];
};

export const AddTrailingSlash = (url) => {
  return !url || url.endsWith('/') || isDocumentLink(url) || isAnchorLink(url) ? url : `${url}/`;
}

export const FormatTags = (data, tags, keys) => {
  const slugMap = {
    topic: 'slug',
    category: 'slug',
    solution: 'id',
    'service-suite': 'id'
  }
  const returnData = []
  tags.forEach((tag, idx) => {
    if (data[tag]) {
      data[tag].forEach((cat) => {
        const type = keys[idx]
        const slugKey = slugMap[type]
        returnData.push({
          name: cat.name,
          slug: cat[slugKey],
          type
        })
      })
    }
  })
  return returnData
}

export const HasBoardLinks = (supportingContent) => {
  if (supportingContent && supportingContent.length > 0) {
    return supportingContent[0].__typename === 'BoardLinks'
  }
  return false
}

// this doesn't work consistently on preview or production
// export const FormatSuperscript = (text) => {
//   if(text.includes("®")){
//     console.info("format ®")
//     text = text.replace(/\®/g, '<sup>®</sup>')
//   }
//   if(text.includes("™")){
//     console.info("format ™")
//     text = text.replace(/\™/g, '<sup>™</sup>')
//   }
//   return text
// }
