import React, { createContext, useState, useEffect } from 'react'
const NavStateContext = createContext()

const NavProvider = ({ children }) => {
  const [navOpen, setNavOpen] = useState()
  const [menuOpen, setMenuOpen] = useState()

  useEffect(() => {
    const handleKey = (ev) => {
      if (ev?.key?.toLowerCase() === 'escape') {
        setMenuOpen(-1)
      }
    }
    window.addEventListener('keyup', handleKey)
    return () => {
      window.removeEventListener('keyup', handleKey)
    }
  }, [])

  const toggleNav = () => {
    setNavOpen(!navOpen)
  }

  const closeNav = () => {
    setNavOpen(false)
  }

  const toggleMenu = (idx) => {
    setMenuOpen(idx)
  }

  return (
    <NavStateContext.Provider value={{ navOpen, toggleNav, closeNav, toggleMenu, menuOpen }}>
      {children}
    </NavStateContext.Provider>
  )
}

export { NavProvider, NavStateContext }
