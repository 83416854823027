import { NavProvider } from 'providers/nav'
import { useEffect } from 'react'
import '../styles/globals.scss'
import { CheckFlexGap } from 'services/utils'
import { CookiesProvider } from 'react-cookie'

function MyApp({ Component, pageProps }) {
  useEffect(() => {
    const isFlexGapSupported = CheckFlexGap()
    if (!isFlexGapSupported) {
      document.querySelector('body').classList.add('no-flex-gap')
    }
  }, [])




  return (
    <NavProvider>
      <CookiesProvider>
        <Component {...pageProps} />
      </CookiesProvider>
    </NavProvider>
  )
}

export default MyApp
